import { getCookie } from './utils'

export const GA_TRACKING_ID = process.env.GA_TRACKING_ID

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  window.gtag?.('config', GA_TRACKING_ID, {
    page_path: url,
    user_id: getCookie('SPC_U'),
  })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = (action, { category, label, value, ...rest }) => {
  window.gtag?.('event', action, {
    event_category: category,
    event_label: label,
    value: value,
    ...rest,
  })
}
