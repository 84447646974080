import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TabBarType } from 'types'

export const name = 'history'

interface intitialStateProps {
  selectingTab: TabBarType
}

const initialState: intitialStateProps = {
  selectingTab: 'won',
}

const historySlice = createSlice({
  name,
  initialState,
  reducers: {
    setSelectingTab: (state, action: PayloadAction<TabBarType>) => {
      state.selectingTab = action.payload
    },
  },
})

export const {
  actions: { setSelectingTab },
} = historySlice

export default historySlice.reducer
