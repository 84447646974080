import 'styles/globals.css'
import 'types/prototype.d'
import type { AppProps, NextWebVitalsMetric } from 'next/app'
import { useRouter } from 'next/router'
import { Provider } from 'react-redux'
import store from 'store'
import GALoader from 'components/GALoader'
import Head from 'next/head'
import { useEffect } from 'react'
import * as gtag from 'shared/gtag'
// import i18next from 'i18next'
import { setIsLogin } from 'store/app.slice'
import { checkIsLogin } from 'shared/utils'
import 'locales/i18n'
import 'shared/dayjs'
import { initSdk, popWebView, resetSdk } from '@shopee/web-bridge-sdk'
// import { useTranslation } from 'react-i18next'

const MyApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter()
  // const { t } = useTranslation()

  // useEffect(() => {
  //   if (router?.query?.lang) i18next.changeLanguage(router.query.lang as string)
  // }, [router?.query?.lang])

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      gtag.pageview(url)
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  useEffect(() => {
    initSdk()
    store.dispatch(setIsLogin(checkIsLogin()))

    return () => {
      popWebView({})
      resetSdk()
    }
  }, [])

  return (
    <>
      <Head>
        <title>Shopee Guess The Price</title>
        <meta name="description" content="Shopee Guess The Price - Alpha Squad" />
        <meta charSet="utf-8" />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
        />
        <script
          defer
          src="https://cdnjs.cloudflare.com/ajax/libs/iamdustan-smoothscroll/0.4.0/smoothscroll.min.js"
        ></script>
      </Head>
      <Provider store={store}>
        <Component {...pageProps} />
      </Provider>
      <GALoader />
    </>
  )
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
  const { id, name, label, value } = metric
  switch (metric.name) {
    case 'FCP':
      // handle FCP results
      break
    case 'LCP':
      // handle LCP results
      break
    case 'CLS':
      // handle CLS results
      break
    case 'FID':
      // handle FID results
      break
    case 'TTFB':
      // handle TTFB results
      break
    // case 'Next.js-hydration':
    //   // handle hydration results
    //   break
    // case 'Next.js-route-change-to-render':
    //   // handle route-change to render results
    //   break
    // case 'Next.js-render':
    //   // handle render results
    //   break
    default:
      break
  }

  gtag.event(name, {
    category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
    label: id, //* id unique to current page load
    value: Math.round(name === 'CLS' ? value * 1000 : value), //* values must be integers
    non_interaction: true, // avoids affecting bounce rate.
  })
}

export default MyApp
