import React from 'react'
import { GA_TRACKING_ID } from 'shared/gtag'
import Script from 'next/script'

const GALoader = () => {
  if (!GA_TRACKING_ID) return null

  return (
    <>
      <Script src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`} />
      <Script id="ga-js">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        `}
      </Script>
    </>
  )
}

export default React.memo(GALoader)
