import { createApi } from '@reduxjs/toolkit/query/react'
import { AxiosRequestHeaders } from 'axios'
import { axiosBaseQuery } from 'shared/axios-settings'
import { timeOffset } from 'shared/utils'
import {
  IGetActivityResponse,
  IGetHistoryItem,
  IGetTasksResponse,
  IGuessedItem,
  IMallItemData,
  IProductInfo,
  ITask,
  IUserChanceResponse,
} from 'types'

// Define a service using a base URL and expected endpoints
const apiSlice = createApi({
  reducerPath: 'campaginApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Activity', 'Slot', 'Tasks', 'Chance', 'History-Won', 'History-Ended'],
  endpoints: (builder) => {
    return {
      getActivityByCode: builder.query<IGetActivityResponse, string>({
        query: (activityCode) => ({
          url: `/guess-price/activity/?activity_code=${activityCode}&limit=4&offset=0`,
          invalidatesTags: (_, error) => {
            if (error) return []
            return ['Slot']
          },
        }),
        // add the offset time the follow server time
        transformResponse: (res: IGetActivityResponse) => ({
          ...res,
          slots: res.slots?.map((slot) => ({
            ...slot,
            begin_time: slot.begin_time + timeOffset,
            end_time: slot.end_time + timeOffset,
          })),
        }),
        providesTags: ['Activity'],
      }),

      getEventByCode: builder.query<
        { items: IGuessedItem[] },
        { activityCode: string; eventCode: string; includedUserState?: boolean }
      >({
        query: ({ activityCode, eventCode, includedUserState = false }) => ({
          url: `/guess-price/slot/?activity_code=${activityCode}&event_code=${eventCode}&include_user_state=${includedUserState}`,
        }),
        providesTags: ['Slot'],
      }),

      getTasks: builder.query<IGetTasksResponse, { activityCode: string; eventCode: string }>({
        query: ({ activityCode, eventCode }) => ({
          url: `/delta/tasks/?activity_code=${activityCode}&event_code=${eventCode}`,
          headers: {
            'Gp-App-Id': process.env.APP_ID,
          } as AxiosRequestHeaders,
        }),
        providesTags: ['Tasks'],
      }),

      performTask: builder.mutation<unknown, { activityCode: string; eventCode: string; taskId: ITask['id'] }>({
        query: ({ activityCode, eventCode, taskId }) => ({
          url: `/delta/tasks/`,
          method: 'POST',
          data: {
            activity_code: activityCode,
            event_code: eventCode,
            task_id: taskId,
          },
          headers: {
            'Gp-App-Id': process.env.APP_ID,
          } as AxiosRequestHeaders,
        }),
      }),

      getUserChance: builder.query<IUserChanceResponse, { eventCode: string; activityCode: string }>({
        query: ({ eventCode, activityCode }) => ({
          url: `/guess-price/user-chance/?activity_code=${activityCode}&event_code=${eventCode}`,
        }),
        providesTags: ['Chance'],
      }),

      getMallData: builder.query<{ items: IMallItemData[] }, { items: IProductInfo[]; country?: string }>({
        query: ({ items, country = 'VN' }) => ({
          url: '/mall/get-items/',
          method: 'POST',
          data: { country, items },
        }),
      }),

      guessPrice: builder.mutation<
        unknown,
        { activityCode: string; eventCode: string; itemUid: IGuessedItem['item_uid']; price: number }
      >({
        query: ({ activityCode, eventCode, itemUid, price }) => ({
          url: `/guess-price/guess/`,
          method: 'POST',
          data: {
            activity_code: activityCode,
            event_code: eventCode,
            item_uid: itemUid,
            price,
          },
        }),
        invalidatesTags: ['Chance', 'Slot'],
      }),

      getHistoryWonItems: builder.query<IGetHistoryItem, { limit: number; offset: number }>({
        query: ({ limit, offset }) => ({
          url: `/guess-price/history/?limit=${limit}&offset=${offset}&request_type=WON_ITEMS`,
        }),
        transformResponse: (res: IGetHistoryItem) => {
          return res
        },
        providesTags: ['History-Won'],
      }),

      getHistoryEndedItems: builder.query<IGetHistoryItem, { limit: number; offset: number }>({
        query: ({ limit, offset }) => ({
          url: `/guess-price/history/?limit=${limit}&offset=${offset}&request_type=NOT_WINNING_ITEMS`,
        }),
        providesTags: ['History-Ended'],
        transformResponse: (res: IGetHistoryItem) => {
          return res
        },
      }),

      claimPrize: builder.mutation<unknown, { item_uid: number }>({
        query: ({ item_uid }) => ({
          url: `/guess-price/claim-prize/`,
          method: 'POST',
          data: {
            item_uid,
          },
        }),
        // invalidatesTags: ['History-Won'], We update local, no need to refetch won history
      }),

      addToCart: builder.mutation<unknown, { item_uid: number }>({
        query: ({ item_uid }) => ({
          url: `/guess-price/add-cart/`,
          method: 'POST',
          data: {
            item_uid,
          },
        }),
        invalidatesTags: ['Slot'],
      }),
    }
  },
})

export const {
  useGetActivityByCodeQuery,
  useGetEventByCodeQuery,
  useGetTasksQuery,
  usePerformTaskMutation,
  useGuessPriceMutation,
  useGetUserChanceQuery,
  useGetMallDataQuery,
  useGetHistoryWonItemsQuery,
  useGetHistoryEndedItemsQuery,
  useLazyGetHistoryEndedItemsQuery,
  useLazyGetHistoryWonItemsQuery,
  useClaimPrizeMutation,
  useAddToCartMutation,
} = apiSlice

export default apiSlice
