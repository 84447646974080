import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosShareApiBaseQuery } from 'shared/axios-settings'
import {
  IAddShareResponse,
  IGetFriendListResponse,
  IGetShareLinkResponse,
  IShareViaChatParams,
  ISharingHandler,
} from 'types'

interface IGetFriendListServerResponse extends IGetFriendListResponse {
  pagination: {
    limit: number
    offset: number
  }
  code: number
  msg: string
}

interface IAddShareServerResponse {
  code: number
  msg: string
  data: IAddShareResponse
}

const shareAPISlice = createApi({
  reducerPath: 'newShareApi',
  tagTypes: ['Friend', 'Link'],
  baseQuery: axiosShareApiBaseQuery(),
  endpoints: (builder) => {
    return {
      getFriendList: builder.query<
        IGetFriendListResponse,
        { activityCode: string; eventCode: string; deviceID: string; limit?: number; offset?: number }
      >({
        query: ({ activityCode, eventCode, deviceID, offset = 0, limit = 10 }) => ({
          url: `/guess-price/share/friends/?activity_code=${activityCode}&event_code=${eventCode}&device_id=${deviceID}&offset=${offset}&limit=${limit}`,
        }),
        transformResponse: (response: IGetFriendListServerResponse) => ({
          data: response.data,
          total: response.total,
        }),
        providesTags: (result) => {
          if (result && result.data) {
            return [...result.data?.map(({ user_id }) => ({ type: 'Friend' as const, id: user_id }))]
          } else {
            return ['Friend']
          }
        },
      }),
      getShareLink: builder.query<IGetShareLinkResponse, ISharingHandler>({
        query: ({ activityCode, eventCode, snsType, objectType, micrositeID }) => ({
          url: `/guess-price/share/share-link/?activity_code=${activityCode}&event_code=${eventCode}&microsite_id=${micrositeID}&sns_type=${snsType}&object_type=${objectType}`,
        }),
        transformResponse: (res: { code: number; msg: string; data: IGetShareLinkResponse }) => {
          return res.data
        },
        providesTags: ['Link'],
      }),
      shareViaChat: builder.mutation<unknown, IShareViaChatParams>({
        query: ({ shareID, micrositeID, toUserID, appSignature }) => ({
          url: `/guess-price/share/share-via-chat/`,
          method: 'POST',
          data: {
            share_id: shareID,
            microsite_id: micrositeID,
            dest_user_id: toUserID,
            app_signature: appSignature,
          },
        }),
      }),
      addShare: builder.mutation<IAddShareResponse, { shareID: string; toUserID?: number }>({
        query: ({ shareID, toUserID = 0 }) => ({
          url: `/guess-price/share/add-share/`,
          method: 'POST',
          data: {
            share_id: shareID,
            dest_shopee_user_id: toUserID,
          },
        }),
        transformResponse: (response: IAddShareServerResponse) => {
          return response.data
        },
        // invalidatesTags: (result, error, arg) => [{ type: 'Friend', id: arg.toUserID }],
      }),
    }
  },
})

export const { useLazyGetFriendListQuery, useLazyGetShareLinkQuery, useAddShareMutation, useShareViaChatMutation } =
  shareAPISlice

export default shareAPISlice
