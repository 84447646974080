import { isWebBridgeSupported, navigate, navigateAppRL } from '@shopee/web-bridge-sdk'
import dayjs from 'dayjs'
import i18next from 'i18next'
import router from 'next/router'
import store from 'store'
import apiSlice from 'store/api.slice'
import { DialogType } from 'store/dialogs.slice'
import { IShowToastPayload, toastActions } from 'store/toast.slice'
import { IMallItemData, IProductInfo, ITask, ITimer, ShareObjectType, TaskType } from 'types'
import { setPersistentDialog } from './persistent'
import { isPC, micrositeNavigate } from './webBridge'

export const convertAppPath = (url: string): string => {
  const base64HomeUrl = window.btoa(
    JSON.stringify({
      paths: [{ webNav: { url } }],
    }),
  )
  return `home?navRoute=${window.encodeURIComponent(base64HomeUrl)}`
}

export const convertAppRL = (url: string) => `home?apprl=${encodeURIComponent(decodeURIComponent(url))}`

export const getCookie = (name: string): string | null => {
  if (typeof document === 'undefined') return null
  return (
    document?.cookie
      .split('; ')
      .find((row) => row.startsWith(`${name}=`))
      ?.split('=')[1] || null
  )
}

export const requestTimeout = (fn: () => void, delay: number) => {
  const start = new Date().getTime()
  let rafID: number
  let isLoop = true
  const loop = () => {
    if (isLoop) {
      const current = new Date().getTime()
      const delta = current - start
      if (delta >= delay) fn()
      else rafID = requestAnimationFrame(loop)
    }
  }
  rafID = requestAnimationFrame(loop)
  return {
    id: rafID,
    clear: () => {
      cancelAnimationFrame(rafID)
      isLoop = false
    },
  }
}

export const requestInterval = (fn: () => void, delay: number) => {
  let start = new Date().getTime()
  let rafID: number
  let isLoop = true
  const loop = () => {
    if (isLoop) {
      const current = new Date().getTime()
      const delta = current - start
      if (delta >= delay) {
        fn()
        start = new Date().getTime()
      }
      rafID = requestAnimationFrame(loop)
    }
  }
  rafID = requestAnimationFrame(loop)
  return {
    id: rafID,
    clear: () => {
      cancelAnimationFrame(rafID)
      isLoop = false
    },
  }
}

export const handleRequestError = (err) => {
  // The error is already handled
  if (err?.code) return { status: err.code, data: err }

  // The request don't have the response
  if (!err?.response && err.request) {
    return { status: 403, data: { code: 403, msg: err?.message } }
  }

  // The error don't have the response, mean it not Request error
  if (!err?.response) {
    return { status: 500, data: { code: 500, msg: err?.message || 'Unknown error' } }
  }

  // The formatted error return by Backend
  if (err.response.data?.code) return { status: err.response.status, data: err.response.data }

  // The response error that not handled by Backend
  return {
    status: err.response.status,
    data: { code: err.response.status, msg: err.response.statusText, data: err.response.data },
  }
}

export const currencyFormatter = (value: number) => {
  const formatter = new Intl.NumberFormat('vi-VN', {
    currency: 'VND',
    style: 'currency',
  })
  return formatter.format(+value)
}

export const numberFormatter = (value: number) => {
  return new Intl.NumberFormat('vi-VN', {}).format(value)
}

// This function to check data integrity does not care about security
export const simpleHash = (message: string) => {
  if (message.length === 0) return 0
  return message.split('').reduce((acc, c) => +((acc << 5) - acc + c.charCodeAt(0)), 0)
}

export const sha1Hash = async (message: string) => {
  const encoder = new TextEncoder()
  const data = encoder.encode(message)
  return await window.crypto.subtle.digest('SHA-1', data)
}

export const getMicrositeUrl = (site: string, tag?: string) =>
  `${process.env.SHOPEE_URL}/m/${site}${tag ? `#${tag}` : ''}`

export const getMicrositeUniversalUrl = (site: string) =>
  `${process.env.SHOPEE_URL}/universal-link/m/${site}?deep_and_deffered=1`

export const randomID = (): string => `_${Math.random().toString(36).substr(2, 9)}`

export const checkIsLogin = (): boolean => {
  const userId = getCookie('SPC_U')
  return userId !== null && userId !== '-'
}

export const removeItemByValue = (arr: Array<string | number>, value: string | number) => {
  arr.forEach((e: string | number, i: number) => {
    if (e === value) arr.splice(i, 1)
  })

  return arr
}

export const getRandomNumber = (min: number, max: number) => {
  return Math.random() * (max - min) + min
}

export const isLargeScreen = () => (typeof window === 'undefined' ? false : window.innerWidth >= 960)

export const getEventTabsLabel = (beginTime: number, endTime: number) => {
  if (!beginTime || !endTime) return i18next.t('Chưa có sự kiện')
  if (endTime - Date.now() <= 0) return i18next.t('Đã kết thúc')
  if (beginTime - Date.now() <= 0) return i18next.t('Đang diễn ra')

  const daysDiff = dayjs(beginTime - timeOffset)
    .startOf('d')
    .diff(dayjs().startOf('d'), 'd')
  if (daysDiff === 1) return i18next.t('Ngày mai')
  if (daysDiff === 2) return i18next.t('Ngày mốt')
  if (daysDiff > 2) return dayjs(beginTime).format('DD/MM/YYYY')

  return i18next.t('Sắp diễn ra')
}

// export const getDayLabel = (beginTime: number) => {
//   const daysDiff = dayjs(beginTime).dayOfYear() - dayjs().dayOfYear()

//   if (daysDiff === 0) return i18next.t('Hôm nay')
//   if (daysDiff === 1) return i18next.t('Ngày mai')
//   if (daysDiff === 2) return i18next.t('Ngày mốt')

//   return dayjs(beginTime).format('DD/MM')
// }

// export const toCamelCase = (object) => {
//   if (typeof object !== 'object') return object

//   return Object.keys(object).reduce((obj, key) => {
//     const camalCaseKey = key.replace(/([-_][a-z])/g, (group) => group.slice(-1).toUpperCase())
//     obj[camalCaseKey] = object[key]

//     if (typeof object[key] === 'object') {
//       obj[camalCaseKey] = toCamelCase(object[key])
//     }

//     return obj
//   }, {})
// }

export const parseTextWithColor = (str: string) => {
  const textColorArr: [string, [string]] = JSON.parse(str)
  const [text, [color]] = textColorArr
  return { text, color }
}

let toastTimer: ITimer
export const showToast = (payload: IShowToastPayload, timeout = 3000) => {
  const { toast } = store.getState()
  const hideToast = () => store.dispatch(toastActions.hideToast())
  // Handle the logic if there are another toast want to show up but previous toast haven't finished yet
  if (toast.isShow) {
    hideToast()
    toastTimer?.clear()
  }

  store.dispatch(toastActions.showToast(payload))
  toastTimer = requestTimeout(hideToast, timeout)
}

export const getShopeeShopUrl = (shopId: number) => {
  return `${process.env.SHOPEE_URL}/shop/${shopId}`
}

export const getShopeeItemUrl = (shopId: number, itemId: number) => {
  return `${process.env.SHOPEE_URL}/product/${shopId}/${itemId}`
}

export const getTaskActionText = (type: TaskType) => {
  let actionText = ''

  switch (type) {
    case 'act_follow_shop':
      actionText = i18next.t('Theo dõi')
      break
    case 'act_view_shop':
      actionText = i18next.t('Ghé thăm')
      break
    case 'act_view_item':
      actionText = i18next.t('Xem ngay')
      break
    case 'act_like_item':
      actionText = i18next.t('Thực hiện')
      break
    default:
      break
  }

  return actionText
}

export const executeTask = async (task: ITask) => {
  const {
    id: taskId,
    assets: { shop_id, item_id },
    action_type,
  } = task

  //TODO: Get back to this later when done with task feature
  //* persist to show the task dialog when back from other pages
  // const questionIdx = store.getState().app.currentQuestion
  setPersistentDialog(DialogType.TaskDialog, false)

  if ((action_type === 'act_view_shop' || action_type === 'act_view_item') && task.status === 'AVAILABLE')
    await store.dispatch(
      apiSlice.endpoints.performTask.initiate({
        activityCode: router.query.cid as string,
        eventCode: store.getState().app.selectingEvent as string,
        taskId,
      }),
    )

  if (action_type === 'act_view_item' || action_type === 'act_like_item')
    micrositeNavigate(getShopeeItemUrl(shop_id, item_id))

  if (action_type === 'act_follow_shop' || action_type === 'act_view_shop') micrositeNavigate(getShopeeShopUrl(shop_id))
}

export const getUnifiedLink = (url: string) =>
  `${process.env.SHOPEE_URL}/universal-link?redir=${encodeURIComponent(decodeURIComponent(url))}&deep_and_deferred=1`

export const getSharePageLink = (objectType: ShareObjectType) => {
  const { cid, site } = router.query

  //* use document.referrer to check that we are in web or app
  const from = document.referrer ? 'web' : 'app'
  const event = store.getState().app.selectingEvent

  return `${process.env.BASE_URL}/share?cid=${cid}&event=${event}&site=${site}&object_type=${objectType}&from=${from}`
}

export const handleShareNavigation = (objectType: ShareObjectType) => {
  //TODO: Get back to this later when done with task feature
  //* persist to show the task dialog when back from other pages
  // const questionIdx = store.getState().app.currentQuestion
  if (objectType === 'event') setPersistentDialog(DialogType.TaskDialog, false)
  if (isPC()) micrositeNavigate(`${process.env.BASE_URL}/pending/`)
  else micrositeNavigate(getUnifiedLink(getSharePageLink(objectType)))
}

export const getImage = (image: string) =>
  `${process.env.ENV_ARG === 'dev' ? '' : process.env.BASE_URL}/images/${image}`

export const getCFImage = ({
  url,
  isHash = false,
  isTiny = false,
}: {
  url: string
  isHash?: boolean
  isTiny?: boolean
}) => {
  if (!url) return ''
  return `${isHash ? `https://cf.shopee.vn/file/${url}` : url}${isTiny ? '_tn' : ''}`
}

export let timeOffset = 0
export const setTimeOffset = (serverTime?: number) => {
  if (!serverTime) return null
  timeOffset = Date.now() - serverTime
}

export const formatGuessedPrice = (guessedPrice: number, fallback = '???') =>
  guessedPrice ? numberFormatter(guessedPrice) : fallback

export const parseTextWithItemName = (text: string, itemName: IMallItemData['data']['name']) =>
  text?.replace('{{item_name}}', itemName)

export const isValidHttpUrl = (str: string) => {
  let url: URL

  try {
    url = new URL(str)
  } catch (_) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

export const prizeCheckNavigate = (url: string) => {
  //TODO: check if url is valid in the future

  if (isWebBridgeSupported()) {
    switch (url) {
      case `${process.env.SHOPEE_URL}/user/voucher-wallet`:
        navigateAppRL({ apprl: 'rn/VOUCHER_WALLET_HOME_PAGE', params: { __anim__: 0 } })
        break
      case `${process.env.SHOPEE_URL}/coins`:
        navigate({ url: `${url}/?__mobile__=1` })
        break
      default:
        navigate({ url })
    }
  } else {
    window.open(url, '_blank')
  }
}

export const isItemMatch = (itemA: IProductInfo, itemB: IProductInfo) =>
  itemA.shop_id === itemB.shop_id && itemA.item_id === itemB.item_id && itemA.model_id === itemB.model_id

export const getOriginalPrice = (mallData: IMallItemData['data']) =>
  mallData?.price_before_discount || mallData?.price || 0

export const getItemKey = (item?: IProductInfo) => {
  if (!item) return ''
  return `${item?.shop_id}.${item?.item_id}.${item?.model_id}`
}

export const iOSversion = () => {
  if (/iP(hone|od|ad)/.test(navigator.platform)) {
    const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/) || []
    return parseFloat(`${parseInt(v[1])}.${parseInt(v[2])}`)
  }
  return 0
}
