// import router from 'next/router'
import store from 'store'
import { DialogType } from 'store/dialogs.slice'

export interface IPersistentReminderList {
  [key: string]: IPersistentReminder[]
}

export interface IPersistentReminder {
  beginTime: number
  hash: number
}

const PERSISTENT_REMINDER_LIST_KEY = 'spvnGuessPriceReminderList'
let persistentReminderList: IPersistentReminder[]

// export const getPersistentReminderList = (): IPersistentReminder[] => {
//   const cid = router.query.cid as string
//   if (!persistentReminderList) {
//     persistentReminderList = JSON.parse(localStorage.getItem(PERSISTENT_REMINDER_LIST_KEY) || '{}')
//     //* Remove outdated reminder Data from local Storage to avoid full of storage
//     Object.keys(persistentReminderList).forEach((cid) => {
//       persistentReminderList[cid].forEach((reminder, idx) => {
//         if (reminder.beginTime <= Date.now()) persistentReminderList[cid].splice(idx, 1)
//       })
//       if (persistentReminderList[cid].length === 0) delete persistentReminderList[cid]
//     })
//     //* Write the filtered data to local storage
//     localStorage.setItem(PERSISTENT_REMINDER_LIST_KEY, JSON.stringify(persistentReminderList))
//   }
//   return persistentReminderList[cid] || []
// }

export const getPersistentReminderList = (): IPersistentReminder[] => {
  if (!persistentReminderList) {
    persistentReminderList = JSON.parse(localStorage.getItem(PERSISTENT_REMINDER_LIST_KEY) || '[]')
    if (!Array.isArray(persistentReminderList)) persistentReminderList = []
    //* Remove outdated reminder Data from local Storage to avoid full of storage
    persistentReminderList.forEach((reminder, idx) => {
      if (reminder.beginTime <= Date.now()) persistentReminderList.splice(idx, 1)
    })
    //* Write the filtered data to local storage
    localStorage.setItem(PERSISTENT_REMINDER_LIST_KEY, JSON.stringify(persistentReminderList))
  }
  return persistentReminderList
}

export const updatePersistentReminderList = (reminderList: IPersistentReminder[]) => {
  persistentReminderList = reminderList
  localStorage.setItem(PERSISTENT_REMINDER_LIST_KEY, JSON.stringify(persistentReminderList))
}

const PERSISTENT_DIALOG_KEY = 'spvnGTPDialog'
interface IpersistentDialog {
  [key: string]: { dialog?: DialogType; willRemovePersistentOnOpen?: boolean }
}

export const getPersistentDialog = () => {
  const selectingEvent = store.getState().app.selectingEvent as string
  const persistentDialog: IpersistentDialog = JSON.parse(localStorage.getItem(PERSISTENT_DIALOG_KEY) || '{}')
  return persistentDialog[selectingEvent]
}

export const setPersistentDialog = (dialog?: DialogType, willRemovePersistentOnOpen?: boolean) => {
  const selectingEvent = store.getState().app.selectingEvent as string
  const persistentDialog: IpersistentDialog = { [selectingEvent]: { dialog, willRemovePersistentOnOpen } }
  localStorage.setItem(PERSISTENT_DIALOG_KEY, JSON.stringify(persistentDialog))
}

export const updatePersistentDialog = ({
  selectingEvent,
  willRemovePersistentOnOpen,
}: {
  selectingEvent: string
  willRemovePersistentOnOpen: boolean
}) => {
  let persistentDialog: IpersistentDialog = JSON.parse(localStorage.getItem(PERSISTENT_DIALOG_KEY) || '{}')
  persistentDialog = { [selectingEvent]: { ...persistentDialog[selectingEvent], willRemovePersistentOnOpen } }
  localStorage.setItem(PERSISTENT_DIALOG_KEY, JSON.stringify(persistentDialog))
}

export const removePersistentDialog = () => {
  localStorage.removeItem(PERSISTENT_DIALOG_KEY)
}
