import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IInitialState {
  isShow: boolean
  message: string
  iconType: 'success' | 'failure'
}

export interface IShowToastPayload {
  message: IInitialState['message']
  iconType: IInitialState['iconType']
}

export const name = 'toast'

const initialState: IInitialState = {
  isShow: false,
  message: '',
  iconType: 'success',
}

const toastSlice = createSlice({
  name,
  initialState,
  reducers: {
    showToast: (state, action: PayloadAction<IShowToastPayload>) => {
      const { message, iconType } = action.payload

      state.isShow = true
      state.message = message
      state.iconType = iconType
    },
    hideToast: (state) => {
      state.isShow = false
    },
  },
})

export const { actions: toastActions } = toastSlice

export default toastSlice.reducer
