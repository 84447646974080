import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum DialogType {
  TaskDialog = 'TaskDialog',
  OutOfChanceDialog = 'OutOfChanceDialog',
  GuessPriceDialog = 'GuessPriceDialog',
  ClaimPrizeDialog = 'ClaimPrizeDialog',
  EndedTimeDialog = 'EndedTimeDialog',
}

export const name = 'dialogs'

const initialState: Record<string, unknown> = {}

const dialogsSlice = createSlice({
  name,
  initialState,
  reducers: {
    openDialog: (state, action: PayloadAction<Record<string, unknown> & { dialogType: string }>) => {
      const { dialogType, ...rest } = action.payload
      state[dialogType] = { isOpen: true, ...rest }
    },
    closeDialog: (state, action: PayloadAction<Record<string, unknown> & { dialogType: string }>) => {
      const { dialogType } = action.payload
      const payload = (state?.[dialogType] ?? {}) as Record<string, unknown>
      state[dialogType] = { ...payload, isOpen: false }
    },
    clearDialogs: (state) => {
      Object.keys(state).forEach((key) => delete state[key])
    },
  },
})

export const {
  actions: { openDialog, closeDialog, clearDialogs },
} = dialogsSlice

export default dialogsSlice.reducer
